import { setLocale } from 'yup';

const idiom = localStorage.getItem('idiom');

setLocale(
  idiom === 'en'
    ? {
        mixed: {
          default: 'Invalid field',
          required: 'This field is required',
          notType: 'It must be numeric',
        },
        string: {
          email: 'Invalid email',
          min: 'It must contain at least ${min} characters',
          max: 'It must contain at most ${max} characters',
          matches: 'It must be numeric',
        },
        number: {
          min: 'It must be greater than or equal to ${min}',
          max: 'It must be less than or equal to ${max}',
        }
      }
    : {
        mixed: {
          default: 'Campo invalido',
          required: 'Este campo es requerido',
          notType: 'Debe ser númerico',
        },
        string: {
          email: 'Correo inválido',
          min: 'Debe contener al menos ${min} caracteres',
          max: 'Debe contener a lo más ${max} caracteres',
          matches: 'Debe ser númerico',
        },
        number: {
          min: 'Debe ser mayor o igual a ${min}',
          max: 'Debe ser menor o igual a ${max}',
        }
      },
);

export const localeUpdate = () => {};
