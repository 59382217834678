import React from 'react';
import { useTranslation } from 'react-i18next';

export const WhatIs = () => {
  const [t] = useTranslation('global');
  const items = [
    t('tutorial.what-is.box1'),
    t('tutorial.what-is.box2'),
    t('tutorial.what-is.box3'),
    t('tutorial.what-is.box4'),
    t('tutorial.what-is.box5'),
  ];
  return (
    <>
      <h2 className="mt-1">{t('tutorial.what-is.title')}</h2>
      <div className="mt-2">
        {t('tutorial.what-is.text1')}
        <br />
        <br />
        {t('tutorial.what-is.text2')}
      </div>

      {items.map((item, index) => (
        <div key={index} className="home__box-tertiary mt-3">
          {item}
        </div>
      ))}
    </>
  );
};
