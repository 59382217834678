export const types = {
  authCheckingFinish: '[auth] Finish checking login state',
  authLogin: '[auth] Login',
  authLogout: '[auth] Logout',
  directoryFindAll: '[directory] findAll',
  directoryUpdate: '[directory] update',
  directorySetPage: '[directory] setPage',
  contactFindAll: '[contact] findAll',
  contactSetPage: '[contact] setPage',
  contactCreate: '[contact] create',
  contactUpdate: '[contact] update',
  contactRemove: '[contact] remove',
};
