import React, { useContext } from 'react';
import { CircularProgress, Backdrop } from '@mui/material';
import { Context } from '../utils/UserContext';

export const Loading = () => {
  const { loading } = useContext(Context);
  return (
    <Backdrop open={loading} style={{ zIndex: '9999' }}>
      <CircularProgress className="text-white" />
    </Backdrop>
  );
};
