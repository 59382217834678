import React from 'react';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import { Pagination } from '@mui/material';

export const Paginator = React.memo(
  ({ data: { total_pages, page, page_size, total }, setPage }) => {
    const [t] = useTranslation('global');

    const handleChange = (e, value) => {
      setPage(value);
    };

    const results = () => {
      const page_actual = page_size * page;

      return `${page_actual - (page_size - 1)} - 
      ${page_actual > total ? total : page_actual} 
      ${t('paginator.of')} ${total}`;
    };

    return (
      <div className="row justify-between items-center mt-2">
        <div className="font-14">
          {t('paginator.results')}: {results()}
        </div>
        <Pagination
          count={total_pages}
          page={page}
          className="paginator"
          onChange={handleChange}
        />
      </div>
    );
  },
);

Paginator.propTypes = {
  data: PropTypes.object,
  setPage: PropTypes.func,
};
