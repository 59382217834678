import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as propTypes from 'prop-types';
import { Button, IconButton } from '@mui/material';
import { NavigateBefore, NavigateNext, KeyboardArrowDown } from '@mui/icons-material';

export const FrequentQuestions = ({ close }) => {
  const [t] = useTranslation('global');
  const [active, setActive] = useState(-1);

  const options = [
    {
      question: t('frequent-questions.option1.question'),
      response: t('frequent-questions.option1.response'),
    },
    {
      question: t('frequent-questions.option2.question'),
      response: t('frequent-questions.option2.response'),
    },
    {
      question: t('frequent-questions.option3.question'),
      response: t('frequent-questions.option3.response'),
    },
    {
      question: t('frequent-questions.option4.question'),
      response: t('frequent-questions.option4.response'),
    },
    {
      question: t('frequent-questions.option5.question'),
      response: t('frequent-questions.option5.response'),
    },
    {
      question: t('frequent-questions.option6.question'),
      response: t('frequent-questions.option6.response'),
    },
    {
      question: t('frequent-questions.option7.question'),
      response: t('frequent-questions.option7.response'),
    },
    {
      question: t('frequent-questions.option8.question'),
      response: t('frequent-questions.option8.response'),
    },
    {
      question: t('frequent-questions.option9.question'),
      response: t('frequent-questions.option9.response'),
    },
    {
      question: t('frequent-questions.option10.question'),
      response: t('frequent-questions.option10.response'),
    },
    {
      question: t('frequent-questions.option11.question'),
      response: t('frequent-questions.option11.response'),
    },
  ];

  return (
    <>
      <div>
        <Button
          onClick={close}
          startIcon={<NavigateBefore />}
          className="text-info"
        >
          {t('go-back')}
        </Button>
      </div>
      <h2 className="mt-1">{t('frequent-questions.title')}</h2>
      <div className="mt-2">
        {t('frequent-questions.text')}{' '}
        <span className="login__link font-16">soporte@chazki.com</span>
      </div>
      {options.map(({ question, response }, index) => (
        <div key={question}>
          {active != index ? (
            <Button
              variant="outlined"
              className="home__btn-faq mt-3 border-tertiary text-tertiary"
              fullWidth
              endIcon={<NavigateNext />}
              onClick={() => setActive(index)}
            >
              {question}
            </Button>
          ) : (
            <div className="mt-3 mobile-use">
              <div className="head row items-center text-center">
                <div>{question}</div>
                <div>
                  <IconButton
                    size="small"
                    className="btn-arrow-down"
                    onClick={() => setActive(-1)}
                  >
                    <KeyboardArrowDown className="text-white" />
                  </IconButton>
                </div>
              </div>
              <div
                className="body"
                dangerouslySetInnerHTML={{ __html: response }}
              ></div>
            </div>
          )}
        </div>
      ))}
      <div className="mt-3 font-14">
        {t('frequent-questions.info1')}{' '}
        <strong> {t('frequent-questions.info2')}</strong>.
      </div>
    </>
  );
};

FrequentQuestions.propTypes = {
  close: propTypes.func,
};
