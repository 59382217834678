import React from 'react';
import * as PropTypes from 'prop-types';

export const NoRecords = ({ title, children }) => {
  return (
    <div className="text-info column flex-center flex-1 my-2">
      <div className="max-300 text-center">
        <h2>{title}</h2>
        {children && <div className="font-14 mt-1">{children}</div>}
      </div>
    </div>
  );
};

NoRecords.propTypes = {
  title: PropTypes.string,
  children: PropTypes.string,
};
