import React from 'react';
import { useTranslation } from 'react-i18next';

export const WhoCanUseIt = () => {
  const [t] = useTranslation('global');

  const items = [
    t('tutorial.who-can-use-it.box1'),
    t('tutorial.who-can-use-it.box2'),
    t('tutorial.who-can-use-it.box3'),
    t('tutorial.who-can-use-it.box4'),
  ];
  return (
    <>
      <h2 className="mt-1 mb-2">{t('tutorial.who-can-use-it.title')}</h2>
      {items.map((item, index) => (
        <div key={index} className="home__box-tertiary mt-3">
          {item}
        </div>
      ))}
    </>
  );
};
