import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as propTypes from 'prop-types';
import { Button } from '@mui/material';
import { NavigateNext,NavigateBefore } from '@mui/icons-material';
import { WhatIs } from './tutorial/WhatIs';
import { HowToUse } from './tutorial/HowToUse';
import { Requirements } from './tutorial/Requirements';
import { IsSafe } from './tutorial/IsSafe';
import { WhoCanUseIt } from './tutorial/WhoCanUseIt';

const Tutorial = ({ close }) => {
  const [t] = useTranslation('global');
  const [index, setIndex] = useState(-1);

  const options = [
    {
      text: t('tutorial.what-is.title'),
      html: <WhatIs />,
    },
    {
      text: t('tutorial.how-to-use.title'),
      html: <HowToUse />,
    },
    {
      text: t('tutorial.requirements.title'),
      html: <Requirements />,
    },
    {
      text: t('tutorial.is-safe.title'),
      html: <IsSafe />,
    },
    {
      text: t('tutorial.who-can-use-it.title'),
      html: <WhoCanUseIt />,
    },
  ];

  return (
    <>
      {index === -1 ? (
        <>
          <div>
            <Button
              onClick={close}
              startIcon={<NavigateBefore />}
              className="text-info"
            >
              {t('go-back')}
            </Button>
          </div>
          <h2 className="mt-1">{t('tutorial.title')}</h2>
          <div className="mt-2">{t('tutorial.text')}</div>
          {options.map(({ text }, index) => (
            <Button
              key={text}
              variant="outlined"
              className="home__btn-faq mt-3 border-tertiary text-tertiary"
              fullWidth
              endIcon={<NavigateNext />}
              onClick={() => setIndex(index)}
            >
              {text}
            </Button>
          ))}
        </>
      ) : (
        <>
          <div>
            <Button
              onClick={() => setIndex(-1)}
              startIcon={<NavigateBefore />}
              className="text-info"
            >
              {t('go-back')}
            </Button>
          </div>
          {options[index]?.html}
        </>
      )}
    </>
  );
};

Tutorial.propTypes = {
  close: propTypes.func,
};

export { Tutorial };
