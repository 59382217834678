import React from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';

export const ButtonGroup = ({ locations }) => {
  const [t] = useTranslation('global');

  const colors = ['#F8D374', '#4B68FF', '#B75333', '#D75CE1'];

  const handleClick = (e) => {
    e.stopPropagation();
  };
  return (
    <>
      <div className="btn-group mt-2">
        {new Array(...locations).splice(0, 4).map((item, index) =>
          index < 4 || locations.length === 4 ? (
            <Button
              key={item.id}
              className="btn-rounded"
              onClick={handleClick}
              style={{ border: `3px solid ${colors[index]}` }}
            >
              {item.internal_name?.substr(0, 2).toUpperCase()}
            </Button>
          ) : (
            <Button
              className="btn-rounded text-white"
              style={{ background: '#D75CE1' }}
              onClick={handleClick}
            >
              +{locations.length - 3}
            </Button>
          ),
        )}
      </div>
      <div className="text-success text-center my-2">
        {t('locations.active')} {locations.length} {t('locations.name')}
      </div>
    </>
  );
};

ButtonGroup.propTypes = {
  locations: PropTypes.array,
};
