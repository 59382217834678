import React from 'react';
import { SwipeableDrawer } from '@mui/material';
import * as propTypes from 'prop-types';

const FaqsSideNav = ({ drawer, setDrawer, html }) => {
  return (
    <>
      <SwipeableDrawer
        anchor="right"
        open={drawer}
        onClose={() => setDrawer(true)}
        onOpen={() => setDrawer(true)}
      >
        <div className="p-3 sidenav-width">{html}</div>
      </SwipeableDrawer>
    </>
  );
};

FaqsSideNav.propTypes = {
  drawer: propTypes.bool,
  setDrawer: propTypes.func,
  html: propTypes.object,
};

export { FaqsSideNav };
