import { types } from '../types/types';

const initialState = {
  directories: [],
  paginator: {
    page: 1,
  },
  reload: false,
};

export const directoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.directoryFindAll:
      return {
        directories: action.directories,
        paginator: action.paginator,
        reload: state.reload,
      };

    case types.directoryUpdate:
      return {
        directories: state.directories,
        paginator: state.paginator,
        reload: !state.reload,
      };

    case types.directorySetPage:
      return {
        directories: state.directories,
        paginator: {
          ...state.paginator,
          page: action.page,
        },
        reload: !state.reload,
      };

    default:
      return state;
  }
};
