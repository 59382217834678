import { types } from '../types/types'
import axios from 'axios'

const { REACT_APP_API_URL: URL, REACT_APP_CLIENT_ID: client_id } = process.env

export const SignIn = (username, password) => {
  return async (dispatch) => {
    try {
      const params = new URLSearchParams()
      params.append('grant_type', 'password')
      params.append('username', username)
      params.append('password', password)
      params.append('client_id', client_id)
      const response = await axios.post(`${URL}/oauth/authorize`, params)
      const { access_token: token, scope, user } = response.data
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
      dispatch(
        login({
          token,
          scope,
          payload: user,
        })
      )
    } catch (error) {
      throw error
    }
  }
}

export const startChecking = () => {
  return async (dispatch, state) => {
    try {
      const { token } = state().auth
      if (!token) {
        throw 'without authorization'
      }
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
      await axios.get(`${URL}/oauth/token`)
      dispatch(checkingFinish())
    } catch (error) {
      localStorage.removeItem('state')
      dispatch(logout())
    }
  }
}

const checkingFinish = () => ({ type: types.authCheckingFinish })

const login = (user) => ({
  type: types.authLogin,
  payload: user,
})

export const startLogout = () => {
  return async (dispatch) => {
    localStorage.removeItem('state')
    dispatch(logout())
    await axios.delete(`${URL}/oauth/token`)
  }
}

const logout = () => ({ type: types.authLogout })
