import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Button, Grid, Card, Hidden } from '@mui/material';
import { MailOutline, LockOutlined, NavigateNext } from '@mui/icons-material';
import { SignIn } from '../../actions/authActions';
import { TranslateMenu } from '../../components/TranslateMenu';
import { Input } from '../../components/FormComponents';
import { Context } from '../../utils/UserContext';
import Logo from '../../components/Logo';
import mailboxLocker from '../../assets/images/mailbox-locker.png';

const initialValues = {
  email: '',
  password: [],
};

const validationSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

export const Login = () => {
  const [t] = useTranslation('global');

  const { setLoading, setSnackbar } = useContext(Context);
  const dispatch = useDispatch();
  const { handleChange, handleSubmit, errors, values, touched } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async ({ email, password }) => {
      try {
        setLoading(true);
        await dispatch(SignIn(email, password));
      } catch (error) {
        setSnackbar({
          show: true,
          type: 'error',
          text: 'Usuario o contraseña incorrectos',
        });
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Grid container>
      <Hidden smDown>
        <Grid item md={6} className='login__container-left'>
          <div>
            <div>{t('login.title')}</div>
            <div>{t('login.subtitle')}</div>
            <Button
              target='_blank'
              href='https://lockers-hub.chazki.com'
              variant='contained'
              color='primary'
              className='btn-round btn-xlarge'
              endIcon={<NavigateNext />}>
              {t('login.btn-demo')}
            </Button>
          </div>
          <img className='img-mailbox-locker' src={mailboxLocker} alt='mailbox' />
        </Grid>
      </Hidden>

      <Grid item xs={12} md={6}>
        <div className='column flex-center full-view'>
          <div className='translate-absolute'>
            <TranslateMenu />
          </div>
          <div className='p-3'>
            <div className='text-center'>
              <Logo />
            </div>
            <Card className='login__card'>
              <form
                noValidate
                autoComplete='off'
                className='column space-y-3'
                onSubmit={handleSubmit}>
                <Input
                  label={t('login.email')}
                  name='email'
                  touched={touched.email}
                  value={values.email}
                  error={errors.email}
                  handleChange={handleChange}
                  icon={<MailOutline />}
                />

                <Input
                  label={t('login.password')}
                  name='password'
                  touched={touched.password}
                  value={values.password}
                  error={errors.password}
                  handleChange={handleChange}
                  icon={<LockOutlined />}
                  password
                />

                <div className='text-right'>
                  <Link to='/forgot-password' className='login__link'>
                    {t('login.forgot')}
                  </Link>
                </div>
                <div>
                  <Button variant='contained' color='primary' fullWidth type='submit'>
                    {t('login.btn-login')}
                  </Button>
                </div>

                <div className='font-12'>
                  {t('login.link')} &nbsp;
                  <Link to='/login' className='login__link'>
                    {t('login.terms')}
                  </Link>
                </div>
              </form>
            </Card>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};
