import React, { useContext } from 'react';
import { Lockers } from '../../../components/directories/lockers/Lockers';
import { LockersActions } from '../../../components/directories/lockers/LockersActions';
import { ContactActions } from '../../../components/directories/contacts/ContactActions';
import { ContactList } from '../../../components/directories/contacts/ContactList';
import { ContactSideNav } from '../../../components/directories/contacts/ContactSideNav';
import { SectionHeader } from '../../../components/layout/SectionHeader';
import { useTranslation } from 'react-i18next';
import { Context } from '../../../utils/UserContext';

export const Directory = () => {
  const { toggleGroup } = useContext(Context);
  const [t] = useTranslation('global');
  return (
    <>
      <SectionHeader />
      <ContactActions />
      <ContactList />
      <LockersActions />
      {toggleGroup != 'disabled' && (
        <>
          <h3 className="text-info mb-2">{t('lockers.active')}</h3>
          <Lockers isActive={true} />
        </>
      )}
      {/* {toggleGroup != 'active' && (
        <>
          <h3 className="text-info my-2">{t('lockers.without')}</h3>
          <Lockers isActive={false}/>
        </>
      )} */}
      <ContactSideNav />
    </>
  );
};
