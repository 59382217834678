import React, { useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, TextField, InputAdornment, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Search } from '@mui/icons-material';
import { Context } from '../../utils/UserContext';
import { InputFiles } from './InputFiles';

export const DirectoryActions = () => {
  const [t] = useTranslation('global');
  const { setSidenav } = useContext(Context);
  const { directories } = useSelector((state) => state.directory);
  const [typeDirectories, setTypeDirectories] = useState('all');

  const handleSetType = (event, type) => {
    setTypeDirectories(type);
  };

  const openSideNav = () => {
    setSidenav({
      show: true,
      type: true,
      data: {},
    });
  };

  return (
    <>
      <div className='row items-center justify-between wrap pt-2'>
        <h1 className='pb-2 pr-2'>{t('directories.name')}</h1>
        <div className='pb-2'>
          <InputFiles />
          <Button size='large' variant='contained' color='primary' onClick={openSideNav}>
            {t('directories.btn-create')}
          </Button>
        </div>
      </div>
      {directories.length != 0 && (
        <div className='row items-center justify-between wrap'>
          <ToggleButtonGroup
            value={typeDirectories}
            exclusive
            onChange={handleSetType}
            aria-label='text alignment'
            className='directories__toggle pb-2 pr-2'>
            <ToggleButton
              disabled={typeDirectories === 'all'}
              value='all'
              aria-label='left aligned'>
              {t('toggle.all')}
            </ToggleButton>
            <ToggleButton
              disabled={typeDirectories === 'active'}
              value='active'
              aria-label='centered'>
              {t('toggle.active')}
            </ToggleButton>
            <ToggleButton
              disabled={typeDirectories === 'disabled'}
              value='disabled'
              aria-label='right aligned'>
              {t('toggle.disabled')}
            </ToggleButton>
          </ToggleButtonGroup>
          <div className='pb-2'>
            <TextField
              color='secondary'
              variant='outlined'
              size='small'
              placeholder={t('directories.search')}
              style={{ width: '385px' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Search style={{ color: 'var(--info)' }} />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};
