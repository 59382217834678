import axios from 'axios';
const { REACT_APP_API_URL: URL } = process.env;

export const postTicket = (body) => {
  return async () => {
    try {
      await axios.post(`${URL}/host/tickets`, {
        content: body.content,
        subject: body.subject,
      });
      return {
        message: 'the ticket was created successfully',
      };
    } catch (error) {
      throw error.message;
    }
  };
};
