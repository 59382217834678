import React from 'react';
import { useTranslation } from 'react-i18next';

export const Requirements = () => {
  const [t] = useTranslation('global');

  const items = [
    t('tutorial.requirements.box1'),
    t('tutorial.requirements.box2'),
    t('tutorial.requirements.box3'),
    t('tutorial.requirements.box4'),
    t('tutorial.requirements.box5'),
    t('tutorial.requirements.box6'),
    t('tutorial.requirements.box7'),
  ];
  return (
    <>
      <h2 className="mt-1">{t('tutorial.requirements.title')}</h2>
      <div className="mt-2">{t('tutorial.requirements.text')}</div>

      {items.map((item, index) => (
        <div
          key={index}
          className="home__box-tertiary mt-3"
          dangerouslySetInnerHTML={{ __html: item }}
        ></div>
      ))}
    </>
  );
};
