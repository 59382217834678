import React, { useContext, useState } from 'react';
import { Language } from '@mui/icons-material';
import { Button, MenuItem, Menu } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import  { Context } from '../utils/UserContext';

export const TranslateMenu = ({ white }) => {
  const [, i18n] = useTranslation('global');
  const [anchorEl, setAnchorEl] = useState(null);
  const { setLoading } = useContext(Context);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (value) => {
    if (value) {
      setLoading(true);
      i18n.changeLanguage(value);
      localStorage.setItem('idiom', value);
      setTimeout(() => {
        setLoading(false);
      }, 375);
    }
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        style={{ color: white && 'white' }}
        aria-label="more"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        startIcon={<Language />}
      >
        {i18n.language === 'en' ? 'English' : 'Español'}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => handleClose()}
      >
        <MenuItem
          selected={i18n.language === 'es'}
          onClick={() => handleClose('es')}
        >
          Español
        </MenuItem>
        <MenuItem
          selected={i18n.language === 'en'}
          onClick={() => handleClose('en')}
        >
          English
        </MenuItem>
      </Menu>
    </>
  );
};

TranslateMenu.propTypes = {
  white: PropTypes.bool,
};
