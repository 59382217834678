import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import { Grid, Card, Switch } from '@mui/material';
import { NoRecords } from '../../NoRecords';

export const Lockers = ({ isActive }) => {
  const [t] = useTranslation('global');
  const { lockers } = useSelector((state) => state.contact);

  const data = lockers.filter(item => !!item.is_active === isActive);

  return (
    <>
      {data.length ? (
        <>
          <Grid container spacing={3} className="py-1">
            {data.map(
              ({ uuid, is_active, internal_name, address }) => (
                <Grid key={uuid} item xs={12} sm={6} md={4}>
                  <Card className="p-2 font-14 text-secondary space-y-1 r-3">
                    <div className="row justify-between items-center">
                      <div>
                        <strong>{internal_name}</strong>
                      </div>
                      <div>
                        <Switch disabled defaultChecked={is_active} />
                      </div>
                    </div>
                    <div>{uuid}</div>
                    <div className="font-12">
                      {`${address.street} ${address.number}, CP ${address.zip_code}, ${address.town}, ${address.state}, ${address.country}`}
                    </div>
                  </Card>
                </Grid>
              ),
            )}
          </Grid>
        </>
      ) : (
        <NoRecords title={t('lockers.nothing')} />
      )}
    </>
  );
};

Lockers.propTypes = {
  isActive: PropTypes.bool,
};