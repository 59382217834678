import { types } from '../types/types';

const state = JSON.parse(localStorage.getItem('state'));

let initialState;

if (state) {
  initialState = state.auth;
  initialState.checking = true;
} else {
  initialState = {
    checking: true,
  };
}

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.authCheckingFinish:
      return {
        ...state,
        checking: false,
      };

    case types.authLogin:
      return {
        ...state,
        ...action.payload,
        checking: false,
      };

    case types.authLogout:
      return {
        checking: false,
      };

    default:
      return state;
  }
};
