import React from 'react';
import { useSelector } from 'react-redux';
import { FaqsList } from '../../components/home/FaqsList';
import { SectionList } from '../../components/home/SectionList';
import { useTranslation } from 'react-i18next';

export const Home = () => {
  const [t] = useTranslation('global');
  const { payload } = useSelector((state) => state.auth);
  return (
    <>
      <h2>
        {t('home.hello')} {payload.name}!
      </h2>
      <div className="text-secondary my-3">{t('home.section-list')}</div>
      <SectionList />
      <div className="text-secondary my-3">{t('home.faqs-list')}</div>
      <FaqsList />
    </>
  );
};
