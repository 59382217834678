import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { startChecking } from '../actions/authActions';
import { PublicRoute } from './PublicRoute';
import { PrivateRoute } from './PrivateRoute';
import { Login } from '../pages/auth/Login';
import { ForgotPassword } from '../pages/auth/ForgotPassword';
import { Home } from '../pages/profile/Home';
import { NavBar } from '../components/layout/NavBar';
import { Directories } from '../pages/profile/Directories';
import { Directory } from '../pages/profile/directories/Directory';
import { Reservations } from '../pages/profile/Reservations';
import { Lockers } from '../pages/profile/Lockers';
import { Loading } from '../components/Loading';

export const AppRouter = () => {
  const dispatch = useDispatch();
  const { checking, token, payload } = useSelector((state) => state.auth);
  useEffect(() => {
    dispatch(startChecking());
  }, [dispatch]);

  if (checking) {
    return <Loading />;
  }

  return (
    <Router>
      {token && <NavBar username={payload.name} />}
      <Switch>
        <PublicRoute
          exact
          path="/login"
          component={Login}
          isAuthenticated={!!token}
        />

        <PublicRoute
          exact
          path="/forgot-password"
          component={ForgotPassword}
          isAuthenticated={!!token}
        />

        <PrivateRoute
          exact
          path="/"
          component={Home}
          isAuthenticated={!!token}
        />

        <PrivateRoute
          exact
          path="/directories"
          component={Directories}
          isAuthenticated={!!token}
        />

        <PrivateRoute
          exact
          path="/directories/:id"
          component={Directory}
          isAuthenticated={!!token}
        />

        <PrivateRoute
          exact
          path="/reservations"
          component={Reservations}
          isAuthenticated={!!token}
        />

        <PrivateRoute
          exact
          path="/lockers"
          component={Lockers}
          isAuthenticated={!!token}
        />

        <Redirect to="/" />
      </Switch>
    </Router>
  );
};
