import React from 'react';
import {
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  InputAdornment,
} from '@mui/material';
import * as PropTypes from 'prop-types';

export const Input = ({
  name,
  label,
  value,
  touched,
  error,
  handleChange,
  icon,
  password,
}) => {
  return (
    <div>
      {label && (
        <label
          className="label-input"
          style={{
            color: touched && error && 'var(--error)',
          }}
        >
          {label}
        </label>
      )}

      <TextField
        type={password && 'password'}
        variant="outlined"
        color={touched && error ? 'primary' : 'secondary'}
        size="small"
        fullWidth
        name={name}
        value={value}
        onChange={handleChange}
        error={touched && Boolean(error)}
        helperText={touched && error}
        InputProps={
          icon && {
            startAdornment: (
              <InputAdornment position="start">{icon}</InputAdornment>
            ),
          }
        }
      />
    </div>
  );
};

Input.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  touched: PropTypes.bool,
  error: PropTypes.string,
  handleChange: PropTypes.func,
  icon: PropTypes.object,
  password: PropTypes.bool,
};

export const InputRadio = ({
  name,
  label,
  options,
  value,
  touched,
  error,
  handleChange,
}) => {
  return (
    <div>
      <label
        className="label-radio"
        style={{
          color: touched && error && 'var(--error)',
        }}
      >
        {label}
      </label>

      <RadioGroup
        aria-label={name}
        name={name}
        value={value}
        onChange={handleChange}
        className="relative mt-0"
      >
        {options.map(({ value, label }) => (
          <FormControlLabel
            key={value}
            value={value}
            control={<Radio size="small" />}
            label={label}
          />
        ))}
        {touched && error && (
          <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense">
            {error}
          </p>
        )}
      </RadioGroup>
    </div>
  );
};

InputRadio.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.string,
  touched: PropTypes.bool,
  error: PropTypes.string,
  handleChange: PropTypes.func,
};
