import React from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { NavigateBefore } from '@mui/icons-material';
import { Button } from '@mui/material';

export const SectionHeader = () => {
  const [t] = useTranslation('global');

  const history = useHistory();
  const goBack = () => history.goBack();

  return (
    <div>
      <Button
        startIcon={<NavigateBefore />}
        onClick={goBack}
        style={{ color: 'var(--info)' }}
      >
        {t('go-back')}
      </Button>
    </div>
  );
};
