import React, { useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Button, Modal, FormControlLabel, RadioGroup, Radio, TextField } from '@mui/material';
import { Context } from '../../utils/UserContext';
import { useFormik } from 'formik';
import { importCsv } from '../../actions/contactActions';

const initialValues = {
  directory: [],
  archive: [],
};
const validationSchema = yup.object().shape({
  directory: yup.object().required(),
  archive: yup.string().required(),
});

const convertFileBase64 = (file) =>
  new Promise((resolve, reject) => {
    let fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => resolve(fileReader.result);
    fileReader.onerror = (error) => reject(console.log('error', error));
  });

export const InputFiles = () => {
  const [t] = useTranslation('global');
  const dispatch = useDispatch();
  const { setLoading, setSnackbar } = useContext(Context);
  const [open, setOpen] = useState(false);
  const { directories } = useSelector((state) => state.directory);
  const { resetForm, handleChange, values } = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
  });

  const onOpen = () => setOpen(true);

  const onClose = () => {
    setOpen(false);
    resetForm();
  };

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      await dispatch(importCsv(values));
    } catch (error) {
      setSnackbar({
        show: true,
        type: 'error',
        text: 'Error al importar los datos',
      });
    } finally {
      onClose();
      setLoading(false);
    }
  };

  return (
    <>
      <Button size='large' variant='outlined' color='primary' className='mr-2' onClick={onOpen}>
        {t('directories.import')} .csv
      </Button>

      <Modal open={open} onClose={onClose}>
        <div className='modal'>
          <form onSubmit={onSubmit}>
            <p>{t('directories.select-directories')}</p>
            <RadioGroup name='directory' value={values.directory}>
              {directories.map((directory) => (
                <FormControlLabel
                  key={directory.id}
                  label={directory.name}
                  value={directory.id}
                  onChange={() =>
                    handleChange({
                      target: {
                        name: 'directory',
                        value: directory.id,
                      },
                    })
                  }
                  control={<Radio />}
                  required
                />
              ))}
            </RadioGroup>
            <p>{t('directories.drag-drop')}</p>
            <TextField
              name='file'
              type='file'
              accept='.csv'
              onChange={(e) =>
                convertFileBase64(e.target.files[0]).then((file) =>
                  handleChange({
                    target: {
                      name: 'archive',
                      value: file,
                    },
                  }),
                )
              }
            />

            <Button
              type='submit'
              variant='contained'
              disabled={values.directory.length > 0 && values.archive.length > 0 ? false : true}>
              {t('directories.import')}
            </Button>
          </form>
        </div>
      </Modal>
    </>
  );
};
