import React from 'react'
import { useTranslation } from 'react-i18next'
import { Avatar } from '@mui/material'
import * as PropTypes from 'prop-types'

export const ContactDetail = ({
  data: {
    name,
    last_name,
    gender,
    age,
    email,
    phones,
    address: { country, state, city, town, zip_code, street, cross_streets, number, references, delivery_info },
  },
}) => {
  const [t] = useTranslation('global')

  return (
    <div className='space-y-1 mt-3'>
      <div className='row items-center space-x-2'>
        <Avatar className='avatar-size' />
        <h3>
          {name} {last_name}
        </h3>
      </div>
      <div className='row'>
        <div className='flex-1 p-1'>
          <div className='font-14 text-info'>{t('form.gender')}</div>
          <div>{gender}</div>
        </div>
        <div className='flex-1 p-1'>
          <div className='font-14 text-info'>{t('form.age')}</div>
          <div>{age}</div>
        </div>
      </div>
      <div className='p-1'>
        <div className='font-14 text-info'>{t('form.email')}</div>
        <div>{email}</div>
      </div>
      <div className='row'>
        <div className='flex-1 p-1'>
          <div className='font-14 text-info'>{t('form.phone')} 1</div>
          <div>{phones[0]}</div>
        </div>
        <div className='flex-1 p-1'>
          <div className='font-14 text-info'>{t('form.phone')} 2</div>
          <div>{phones[1] ? phones[1] : '-'}</div>
        </div>
      </div>
      <div className='p-1'>
        <div className='font-14 text-info'>{t('form.country')}</div>
        <div>{country ? country : '-'}</div>
      </div>
      <div className='p-1'>
        <div className='font-14 text-info'>{t('form.state')}</div>
        <div>{state ? state : '-'}</div>
      </div>
      <div className='p-1'>
        <div className='font-14 text-info'>{t('form.city')}</div>
        <div>{city ? city : '-'}</div>
      </div>
      <div className='p-1'>
        <div className='font-14 text-info'>{t('form.town')}</div>
        <div>{town ? town : '-'}</div>
      </div>
      <div className='p-1'>
        <div className='font-14 text-info'>{t('form.zip-code')}</div>
        <div>{zip_code ? zip_code : '-'}</div>
      </div>

      <div className='p-1'>
        <div className='font-14 text-info'>{t('form.street')}</div>
        <div>{street ? street : '-'}</div>
      </div>

      <div className='p-1'>
        <div className='font-14 text-info'>{t('form.number')}</div>
        <div>{number ? number : '-'}</div>
      </div>

      <div className='p-1'>
        <div className='font-14 text-info'>{t('form.cross-streets')}</div>
        <div>{cross_streets ? cross_streets : '-'}</div>
      </div>

      <div className='p-1'>
        <div className='font-14 text-info'>{t('form.references')}</div>
        <div>{references ? references : '-'}</div>
      </div>
      <div className='p-1'>
        <div className='font-14 text-info'>{t('form.delivery-info')}</div>
        <div>{delivery_info ? delivery_info : '-'}</div>
      </div>
    </div>
  )
}
ContactDetail.propTypes = {
  data: PropTypes.object,
}
