import React from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  Button,
  ListItemIcon,
  Typography,
  Menu,
  MenuItem,
} from '@mui/material';
import { ExpandMore, ExitToApp, PermIdentity } from '@mui/icons-material';
import { startLogout } from '../../actions/authActions';
import { TranslateMenu } from '../../components/TranslateMenu';
import Logo from '../Logo';

const NavBar = ({ username }) => {
  const [t] = useTranslation('global');
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = () => {
    dispatch(startLogout());
  };

  return (
    <>
      <AppBar position="static" color="secondary">
        <div className="row justify-center full-width">
          <Toolbar className="justify-between max-width-screen">
            <NavLink to="/">
              <Logo size={1} textColor="white" />
            </NavLink>
            <div className="space-x-1">
              <TranslateMenu white />
              <Button
                startIcon={<PermIdentity />}
                endIcon={<ExpandMore />}
                color="inherit"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                {username}
              </Button>
            </div>
          </Toolbar>
        </div>
      </AppBar>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={logOut}>
          <ListItemIcon>
            <ExitToApp fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            {t('log-out')}
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

NavBar.propTypes = {
  username: PropTypes.string,
};

export { NavBar };
