import React from 'react'
import { createContext, useState } from 'react'
import * as PropTypes from 'prop-types'

export const Context = createContext(null)

const UserContext = ({ children }) => {
  const [loading, setLoading] = useState(false)
  const [snackbar, setSnackbar] = useState({
    show: false,
    type: null,
    text: null,
  })
  const [sidenav, setSidenav] = useState({
    show: false,
    type: true,
    data: {},
  })

  const [toggleGroup, setToggleGroup] = useState('all')

  return (
    <Context.Provider
      value={{
        loading,
        setLoading,
        snackbar,
        setSnackbar,
        sidenav,
        setSidenav,
        toggleGroup,
        setToggleGroup,
      }}>
      {children}
    </Context.Provider>
  )
}

UserContext.propTypes = {
  children: PropTypes.array,
}

export { UserContext }
