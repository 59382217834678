import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Button, Card } from '@mui/material'
import { MailOutline } from '@mui/icons-material'
import { Input } from '../../components/FormComponents'
import { TranslateMenu } from '../../components/TranslateMenu'
import Logo from '../../components/Logo'

const validationSchema = yup.object().shape({
  email: yup.string().email().required(),
})

export const ForgotPassword = () => {
  const [t] = useTranslation('global')
  const { touched, values, errors, handleChange, handleSubmit } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values)
    },
  })

  return (
    <div className='column flex-center full-view'>
      <div className='translate-absolute'>
        <TranslateMenu />
      </div>
      <div>
        <div className='text-center'>
          <Logo />
        </div>
        <Card className='forgot__card'>
          <form noValidate autoComplete='off' className='column space-y-2' onSubmit={handleSubmit}>
            <div className='forgot__title'>{t('forgot-password.title')}</div>
            <div className='px-2'>{t('forgot-password.text')}</div>
            <div className='px-2'>
              <Input
                name='email'
                touched={touched.email}
                value={values.email}
                error={errors.email}
                handleChange={handleChange}
                icon={<MailOutline />}
              />
            </div>
            <div className='mt-4 px-2'>
              <Button variant='contained' color='primary' fullWidth type='submit' className='block'>
                {t('forgot-password.btn')}
              </Button>
            </div>

            <div className='text-center'>
              <Link to='/login' className='login__link'>
                {t('forgot-password.go-back')}
              </Link>
            </div>
          </form>
        </Card>
      </div>
    </div>
  )
}
