import React, { useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { SwipeableDrawer, Button } from '@mui/material'
import { NavigateBefore } from '@mui/icons-material'
import { create, upload } from '../../actions/directoryActions'
import { useDispatch } from 'react-redux'
import { Context } from '../../utils/UserContext'
import { Input } from '../FormComponents'

const validationSchema = yup.object().shape({
  name: yup.string().required(),
})

export const DirectorySideNav = () => {
  const [t] = useTranslation('global')
  const dispatch = useDispatch()
  const { sidenav, setSidenav, setSnackbar, setLoading } = useContext(Context)
  const { resetForm, setValues, handleChange, handleSubmit, errors, values, touched } = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true)
        if (sidenav.type) {
          await dispatch(create({ directory: values }))
        } else {
          await dispatch(upload(values))
        }
        setSnackbar({
          show: true,
          type: 'success',
          text: sidenav.type ? t('directories.message-create') : t('directories.message-update'),
        })
      } catch (error) {
        setSnackbar({
          show: true,
          type: 'error',
          text: error,
        })
      } finally {
        setLoading(false)
        reset()
      }
    },
  })

  const reset = () => {
    setSidenav((values) => ({ ...values, show: false }))
    resetForm()
  }

  useEffect(() => {
    if (sidenav.show && !sidenav.type) {
      setValues(sidenav.data)
    }
  }, [sidenav])

  return (
    <SwipeableDrawer
      anchor='right'
      open={sidenav.show}
      onClose={() => setSidenav((values) => ({ ...values, show: true }))}
      onOpen={() => setSidenav((values) => ({ ...values, show: true }))}>
      <div className='sidenav-width p-3'>
        <div>
          <Button startIcon={<NavigateBefore />} onClick={reset} style={{ color: 'var(--info)' }}>
            {t('go-back')}
          </Button>
        </div>
        <h3 className='mt-1'>
          {sidenav.type ? t('new') : t('edit')} {t('directories.directory')}
        </h3>
        <form noValidate autoComplete='off' className='column space-y-4 mt-2' onSubmit={handleSubmit}>
          <Input
            label={t('form.name')}
            name='name'
            touched={touched.name}
            value={values.name}
            error={errors.name}
            handleChange={handleChange}
          />
          <div>
            <Button variant='contained' color='primary' fullWidth type='submit'>
              {sidenav.type ? t('directories.btn-create') : t('form.save')}
            </Button>
          </div>
        </form>
      </div>
    </SwipeableDrawer>
  )
}
