import React from 'react';
import { DirectoryActions } from '../../components/directories/DirectoryActions';
import { SectionHeader } from '../../components/layout/SectionHeader';
import { DirectoryList } from '../../components/directories/DirectoryList';
import { DirectorySideNav } from '../../components/directories/DirectorySideNav';

export const Directories = () => {
  return (
    <>
      <SectionHeader />
      <DirectoryActions />
      <DirectoryList />
      <DirectorySideNav />
    </>
  );
};
