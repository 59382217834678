import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, TextField, InputAdornment } from '@mui/material'
import { Search, FiberManualRecord, Save } from '@mui/icons-material'
import { Context } from '../../../utils/UserContext'
import { save } from '../../../actions/contactActions'

export const ContactActions = () => {
  const [t] = useTranslation('global')
  const dispatch = useDispatch()
  const { setSidenav, setLoading, setSnackbar } = useContext(Context)
  const { contacts, payload } = useSelector((state) => state.contact)

  const { pathname } = useLocation()
  const id = pathname.split('/')[2]

  const openSideNav = () => {
    setSidenav({
      show: true,
      type: 'add',
      data: {},
    })
  }

  const saveChanges = async () => {
    try {
      setLoading(true)
      await dispatch(save(id))
      setSnackbar({
        show: true,
        type: 'success',
        text: t('contacts.save-directory'),
      })
    } catch (error) {
      setSnackbar({
        show: true,
        type: 'error',
        text: error,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <div className='row items-center justify-between wrap pt-2'>
        <div className='pb-2 pr-2'>
          <div className='row items-center'>
            <FiberManualRecord
              fontSize='large'
              style={{
                color: 'var(--success)',
                marginRight: '5px',
              }}
            />
            <h1>
              {t('directories.directory')} {payload?.name}
            </h1>
          </div>
        </div>

        <div className='pb-2 space-x-2'>
          <Button size='large' variant='contained' color='primary' endIcon={<Save />} onClick={saveChanges}>
            {t('save')}
          </Button>
        </div>
      </div>
      <h2 className='mb-1'>{t('contacts.name')}</h2>
      <div className='row-reverse items-center justify-between wrap'>
        <div className='pb-2'>
          <Button size='large' variant='contained' color='primary' onClick={openSideNav}>
            {t('contacts.btn-add')}
          </Button>
        </div>
        {contacts.length != 0 && (
          <div className='pb-2'>
            <TextField
              color='secondary'
              variant='outlined'
              size='small'
              placeholder={t('contacts.search')}
              style={{ width: '385px' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Search style={{ color: 'var(--info)' }} />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        )}
      </div>
    </>
  )
}
