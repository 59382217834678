import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid } from '@mui/material';
import { NavigateNext } from '@mui/icons-material';
import { Tutorial } from './faqs/Tutorial';
import { FaqsSideNav } from './FaqsSideNav';
import { ReportProblem } from './faqs/ReportProblem';
import { FrequentQuestions } from './faqs/FrequentQuestions';

export const FaqsList = () => {
  const [t] = useTranslation('global');
  const [drawer, setDrawer] = useState(false);
  const [view, setView] = useState(null);
  const list = [
    {
      text: t('faqs-list.tutorial'),
      html: <Tutorial close={() => setDrawer(false)} />,
    },
    {
      text: t('faqs-list.report-problem'),
      html: <ReportProblem close={() => setDrawer(false)} />,
    },
    {
      text: t('faqs-list.frequent-questions'),
      html: <FrequentQuestions close={() => setDrawer(false)} />,
    },
    {
      text: t('faqs-list.contact'),
      html: 'contact',
    },
  ];

  const openSideNav = (html) => {
    setView(html);
    setDrawer(true);
  };

  return (
    <>
      <Grid container spacing={2}>
        {list.map(({ text, html }) => (
          <Grid item xs={12} sm={6} key={text}>
            <Button
              variant="contained"
              className="home__btn-faq"
              fullWidth
              endIcon={<NavigateNext />}
              onClick={() => openSideNav(html)}
            >
              {text}
            </Button>
          </Grid>
        ))}
      </Grid>
      <FaqsSideNav drawer={drawer} setDrawer={setDrawer} html={view} />
    </>
  );
};
