import React from 'react';
import { useTranslation } from 'react-i18next';

export const IsSafe = () => {
  const [t] = useTranslation('global');

  const items = [
    t('tutorial.is-safe.box1'),
    t('tutorial.is-safe.box2'),
    t('tutorial.is-safe.box3'),
    t('tutorial.is-safe.box4'),
    t('tutorial.is-safe.box5'),
  ];
  return (
    <>
      <h2 className="mt-1">{t('tutorial.is-safe.title')}</h2>
      <div className="mt-2">{t('tutorial.is-safe.text')}</div>

      {items.map((item, index) => (
        <div key={index} className="home__box-tertiary mt-3">
          {item}
        </div>
      ))}
    </>
  );
};
