import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ToggleButton, ToggleButtonGroup, TextField, InputAdornment } from '@mui/material';
import { Search } from '@mui/icons-material';
import { Context } from '../../../utils/UserContext';

export const LockersActions = () => {
  const [t] = useTranslation('global');
  const { lockers } = useSelector((state) => state.contact);
  const { toggleGroup, setToggleGroup } = useContext(Context);

  const handleSetType = (event, type) => {
    setToggleGroup(type);
  };

  return (
    <>
      <h2 className="my-2">Lockers</h2>
      {lockers.length != 0 && (
        <div className="row items-center justify-between wrap">
          <ToggleButtonGroup
            value={toggleGroup}
            exclusive
            onChange={handleSetType}
            aria-label="text alignment"
            className="directories__toggle pb-2 pr-2"
          >
            <ToggleButton
              disabled={toggleGroup === 'all'}
              value="all"
              aria-label="left aligned"
            >
              {t('toggle.all')}
            </ToggleButton>
            <ToggleButton
              disabled={toggleGroup === 'active'}
              value="active"
              aria-label="centered"
            >
              {t('toggle.active')}
            </ToggleButton>
            <ToggleButton
              disabled={toggleGroup === 'disabled'}
              value="disabled"
              aria-label="right aligned"
            >
              {t('toggle.disabled')}
            </ToggleButton>
          </ToggleButtonGroup>
          <div className="pb-2">
            <TextField
              color="secondary"
              variant="outlined"
              size="small"
              placeholder={t('lockers.search')}
              style={{ width: '385px' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search style={{ color: 'var(--info)' }} />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};
