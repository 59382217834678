import { types } from '../types/types'

const initialState = {
  contacts: [],
  lockers: [],
  payload: {},
  paginator: {
    page: 1,
  },
}

export const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.contactFindAll:
      return action

    case types.contactCreate:
      return {
        contacts: [...state.contacts, action.contact],
        lockers: state.lockers,
        payload: state.payload,
        paginator: action.calPaginator([...state.contacts, action.contact], state.paginator.page),
      }

    case types.contactUpdate:
      return {
        contacts: state.contacts.map((item, index) => {
          if (index === action.index) {
            return action.contact
          } else {
            return item
          }
        }),
        lockers: state.lockers,
        payload: state.payload,
        paginator: state.paginator,
      }

    case types.contactSetPage:
      return {
        contacts: state.contacts,
        lockers: state.lockers,
        payload: state.payload,
        paginator: {
          ...state.paginator,
          page: action.page,
        },
      }

    case types.contactRemove:
      return {
        contacts: state.contacts.filter((item, index) => index !== action.index),
        lockers: state.lockers,
        payload: state.payload,
        paginator: { ...state.paginator, total: state.paginator.total - 1 },
      }

    case types.contactImport:
      return {
        contacts: action.contacts,
        // contacts: state.contacts.map((item, index) => {
        //   if (index === action.index) {
        //     return action.contact
        //   } else {
        //     return item
        //   }
        // }),
        // lockers: state.lockers,
        // payload: state.payload,
        // paginator: state.paginator,
      }

    default:
      return state
  }
}
