import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';
//import lockers from '../../assets/images/lockers.svg';
import folder from '../../assets/images/folder.svg';
import layers from '../../assets/images/layers.svg';

export const SectionList = () => {
  const [t] = useTranslation('global');

  const list = [
    {
      title: t('section-list.directories.title'),
      text: t('section-list.directories.text'),
      img: folder,
      url: '/directories',
    },
    {
      title: t('section-list.reservations.title'),
      text: t('section-list.reservations.text'),
      img: layers,
      url: '/reservations',
    },
    /*
    {
      title: 'Lockers',
      text:
        'Controla los puntos de entrega en tu red y gestiona a sus operadores.',
      img: lockers,
      url: '/lockers',
    },
    */
  ];

  return (
    <Grid container spacing={4}>
      {list.map(({ title, text, url, img }) => (
        <Grid item xs={12} sm={6} md={4} key={title}>
          <Link to={url} className="home__box space-x-3">
            <div className="space-y-1">
              <div>{title}</div>
              <div>{text}</div>
            </div>
            <div className="column justify-end">
              <img src={img} />
            </div>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
};
