import { types } from '../types/types';
import axios from 'axios';
const { REACT_APP_API_URL: URL } = process.env;

const findAllDirectories = (directories, paginator) => ({
  type: types.directoryFindAll,
  directories,
  paginator,
});

const updateDirectories = () => ({
  type: types.directoryUpdate,
});

const setPageDirectory = (page) => ({
  type: types.directorySetPage,
  page,
});

export const findAll = (page) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${URL}/company/directories`, {
        params: {
          page,
          page_size: 6,
        },
      });
      const { directories, ...paginator } = response.data;
      dispatch(findAllDirectories(directories, paginator));
    } catch (error) {
      throw error.message;
    }
  };
};

export const create = (payload) => {
  return async (dispatch) => {
    try {
      await axios.post(`${URL}/company/directories`, payload);
      dispatch(updateDirectories());
    } catch (error) {
      dispatch(updateDirectories());
      throw error.message;
    }
  };
};

export const upload = ({ id, name }) => {
  return async (dispatch) => {
    try {
      await axios.put(`${URL}/company/directories/${id}`, {
        directory: {
          name,
        },
      });
      dispatch(updateDirectories());
    } catch (error) {
      throw error.message;
    }
  };
};

export const setPage = (page) => {
  return async (dispatch) => {
    dispatch(setPageDirectory(page));
  };
};
