import React from 'react';
import { Provider } from 'react-redux';
import { store } from './store/index';
import { AppRouter } from './router/AppRouter';
import { Alert } from './components/Alert';
import { UserContext } from './utils/UserContext';
import { Loading } from './components/Loading';

function App() {
  return (
    <Provider store={store}>
      <UserContext>
        <AppRouter />
        <Alert />
        <Loading />
      </UserContext>
    </Provider>
  );
}

export default App;
