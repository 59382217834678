import { combineReducers } from 'redux';
import { authReducer } from './authReducer';
import { contactReducer } from './contactReducer';
import { directoryReducer } from './directoryReducer';

export const rootReducer = combineReducers({
  auth: authReducer,
  directory: directoryReducer,
  contact: contactReducer,
});
