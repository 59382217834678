import { types } from '../types/types';
import axios from 'axios';
const { REACT_APP_API_URL: URL } = process.env;

const calPaginator = (contacts, page) => ({
  page,
  total: contacts.length,
  total_pages: Math.ceil(contacts.length / 10),
  page_size: 10,
});

const findAllContacts = (data) => ({
  type: types.contactFindAll,
  ...data,
});

const updateContact = (contact, index) => ({
  type: types.contactUpdate,
  contact,
  index,
});

const setPageContact = (page) => ({
  type: types.contactSetPage,
  page,
});

const createContact = (contact) => ({
  type: types.contactCreate,
  contact,
  calPaginator,
});

const removeContact = (index) => ({
  type: types.contactRemove,
  index,
});

const importContact = (contacts) => ({
  type: types.contactUpdate,
  contacts,
});

export const findAll = (directory_id) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${URL}/company/directories/${directory_id}`);
      let { contacts, name, company, locations } = response.data.directory;
      dispatch(
        findAllContacts({
          contacts,
          lockers: locations,
          paginator: calPaginator(contacts, 1),
          payload: {
            name,
            company,
          },
        }),
      );
    } catch (error) {
      throw error.message;
    }
  };
};

export const create = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(createContact(payload));
    } catch (error) {
      throw error.message;
    }
  };
};

export const upload = (payload, index) => {
  return async (dispatch) => {
    try {
      dispatch(updateContact(payload, index));
    } catch (error) {
      throw error.message;
    }
  };
};

export const setPage = (page) => {
  return async (dispatch) => {
    dispatch(setPageContact(page));
  };
};

export const remove = (index) => {
  return async (dispatch) => {
    dispatch(removeContact(index));
  };
};

export const save = (directory_id) => {
  return async (dispatch, state) => {
    try {
      const { contacts } = state().contact;
      await axios.put(`${URL}/company/directories/${directory_id}/contacts`, {
        contacts,
      });
    } catch (error) {
      throw error.message;
    }
  };
};

export const importCsv = ({ directory, archive }) => {
  return async (dispatch) => {
    try {
      await axios.post(`${URL}/company/directories/csv`, {
        csv: archive.split(',')[1],
        directoriesIds: [directory],
      });
      // console.log('response', response);
      dispatch(importContact());
    } catch (error) {
      throw error.message;
    }
  };
};
