import React from 'react';
import * as PropTypes from 'prop-types';

const Logo = ({ size = 2.5, textColor = 'var(--info)' }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={size * 81.6}
      height={size * 30.6}
      viewBox="0 0 816 306"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0,306) scale(0.1,-0.1)"
        fill="var(--primary)"
        stroke="none"
      >
        <path d="M1394 2795 c-525 -82 -929 -412 -1089 -889 -122 -363 -65 -786 149 -1109 51 -77 164 -205 222 -252 27 -21 51 -42 54 -46 14 -18 183 -125 245 -156 65 -31 245 -97 250 -91 1 2 7 62 13 133 6 72 17 187 23 256 12 125 12 126 -13 181 -17 36 -42 68 -74 94 -181 147 -268 275 -304 448 -14 68 -15 213 -1 281 31 161 129 317 259 414 145 109 276 154 452 154 173 0 303 -43 445 -147 148 -110 245 -274 276 -466 35 -225 -69 -489 -250 -631 -88 -69 -108 -89 -128 -131 -34 -67 -35 -81 -23 -201 6 -61 17 -172 24 -245 7 -72 15 -134 17 -136 6 -6 154 47 232 82 94 43 221 128 315 210 273 241 431 590 432 953 0 262 -68 492 -215 721 -57 88 -254 287 -345 348 -288 191 -648 275 -966 225z" />
      </g>
      <g
        transform="translate(0,306) scale(0.1,-0.1)"
        fill={textColor}
        stroke="none"
      >
        <path d="M3460 1480 l0 -1060 525 0 525 0 0 195 0 195 -335 0 -335 0 0 865 0 865 -190 0 -190 0 0 -1060z" />
        <path d="M6280 1480 l0 -1060 195 0 195 0 0 193 0 192 131 130 130 130 22 -30 c12 -16 38 -48 58 -72 20 -23 59 -70 85 -105 26 -35 51 -65 54 -68 6 -5 190 -234 258 -322 l37 -47 228 -1 c263 0 247 -14 140 120 -37 47 -115 146 -173 220 -58 74 -141 179 -185 235 -44 55 -116 146 -159 203 l-79 103 111 112 c61 62 159 162 218 222 59 61 148 152 198 203 l91 92 -221 0 -220 0 -54 -52 c-30 -28 -105 -99 -166 -157 -200 -192 -418 -398 -459 -434 l-40 -36 -3 645 -2 644 -195 0 -195 0 0 -1060z" />
        <path d="M5240 1994 c-211 -25 -425 -146 -550 -311 -58 -77 -118 -197 -146 -293 -27 -96 -26 -317 4 -419 80 -274 276 -472 560 -563 92 -30 333 -32 432 -4 308 89 530 338 586 658 69 398 -190 799 -586 907 -74 20 -232 33 -300 25z m265 -429 c164 -78 266 -267 236 -438 -26 -148 -113 -261 -251 -325 -48 -22 -69 -25 -161 -26 -102 -1 -107 0 -181 37 -256 126 -316 460 -120 662 57 58 101 87 167 108 99 32 219 25 310 -18z" />
      </g>
    </svg>
  );
};

Logo.propTypes = {
  size: PropTypes.number,
  textColor: PropTypes.string,
};

export default Logo;
