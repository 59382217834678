import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigateNext, KeyboardArrowDown } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';

export const HowToUse = () => {
  const [t] = useTranslation('global');
  const [active, setActive] = useState(-1);

  const options = [
    {
      question: t('tutorial.how-to-use.option1.question'),
      response: (
        <>
          {t('tutorial.how-to-use.option1.point1')}
          <br />
          <br />
          {t('tutorial.how-to-use.option1.point2')}
          <br />
          <br />
          {t('tutorial.how-to-use.option1.point3')}
          <br />
          <br />
          {t('tutorial.how-to-use.option1.point4')}
          <br />
          <br />
          {t('tutorial.how-to-use.option1.point5')}
          <br />
          <br />
          {t('tutorial.how-to-use.option1.point6')}
          <br />
          <br />
          {t('tutorial.how-to-use.option1.point7')}
          <br />
          <br />
          {t('tutorial.how-to-use.option1.point8')}
        </>
      ),
    },
  ];

  return (
    <>
      <h2 className="mt-1">{t('tutorial.how-to-use.title')}</h2>

      {options.map(({ question, response }, index) => (
        <div key={question}>
          {active != index ? (
            <Button
              variant="outlined"
              className="home__btn-faq mt-3 border-tertiary text-tertiary"
              fullWidth
              endIcon={<NavigateNext />}
              onClick={() => setActive(index)}
            >
              {question}
            </Button>
          ) : (
            <div className="mt-3 mobile-use">
              <div className="head row justify-between items-center text-center">
                <div>{question}</div>
                <div>
                  <IconButton
                    size="small"
                    className="btn-arrow-down"
                    onClick={() => setActive(-1)}
                  >
                    <KeyboardArrowDown className="text-white" />
                  </IconButton>
                </div>
              </div>
              <div className="body">{response}</div>
            </div>
          )}
        </div>
      ))}
    </>
  );
};
