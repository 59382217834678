import global_es from '../translations/global_es.json';
import global_en from '../translations/global_en.json';
import i18next from 'i18next';

const idiom = localStorage.getItem('idiom');

i18next.init({
  interpolation: { escapeValue: false },
  lng: idiom ? idiom : 'es',
  resources: {
    es: {
      global: global_es,
    },
    en: {
      global: global_en,
    },
  },
});

export default i18next;
