import { createTheme } from '@mui/material/styles';

export default createTheme({
  palette: {
    primary: {
      main: '#FF4D4B',
    },
    secondary: {
      main: '#383838',
    },
    error: {
      main: '#ff3633',
    },
    warning: {
      main: '#ffb74d',
    },
    info: {
      main: '#757575',
    },
    success: {
      main: '#32C8BF',
    },
  },
});
