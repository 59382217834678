import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Edit, ChevronRight }  from '@mui/icons-material';
import { Avatar, Grid, Card, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Paginator } from '../../Paginator';
import { findAll, setPage } from '../../../actions/contactActions';
import { Context } from '../../../utils/UserContext';
import { NoRecords } from '../../NoRecords';

export const ContactList = () => {
  const [t] = useTranslation('global');
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const id = pathname.split('/')[2];
  const { contacts, paginator } = useSelector((state) => state.contact);
  const { page, page_size } = paginator;
  const { setSidenav, setLoading, setSnackbar } = useContext(Context);

  useEffect(async () => {
    if (!contacts.length) {
      try {
        setLoading(true);
        await dispatch(findAll(id));
      } catch (error) {
        setSnackbar({
          show: true,
          type: 'error',
          text: error,
        });
      } finally {
        setLoading(false);
      }
    }
  }, []);

  const editUser = (data, index) => {
    setSidenav({
      show: true,
      type: 'edit',
      data,
      index,
    });
  };

  const openDetail = (data, index) => {
    setSidenav({
      show: true,
      type: 'detail',
      data,
      index,
    });
  };

  return (
    <>
      {contacts.length ? (
        <>
          <Grid container spacing={2} className="p-2 user-list-scroll">
            {[...contacts]
              .splice(page_size * page - page_size, page_size)
              .map((user, index) => (
                <Grid item xs={12} key={index}>
                  <Card
                    variant="outlined"
                    className="row items-center wrap justify-between p-2 font-14  text-secondary"
                  >
                    <div className="row items-center space-x-2">
                      <Avatar className="avatar-size" />
                      <strong>
                        {user.name} {user.last_name}
                      </strong>
                    </div>
                    <div>
                      <div>{user.email}</div>
                    </div>
                    <div>
                      <div>{user.phones[0]}</div>
                    </div>
                    <div className="column">
                      <Button
                        size="small"
                        color="primary"
                        startIcon={<Edit />}
                        onClick={() => {
                          editUser(user, (page - 1) * page_size + index);
                        }}
                      >
                        {t('edit')}
                      </Button>

                      <Button
                        className="mt-1"
                        size="small"
                        endIcon={<ChevronRight />}
                        onClick={() => {
                          openDetail(user, index);
                        }}
                      >
                        {t('detail')}
                      </Button>
                    </div>
                  </Card>
                </Grid>
              ))}
          </Grid>
          <Paginator
            data={paginator}
            setPage={(value) => dispatch(setPage(value))}
          />
        </>
      ) : (
        <NoRecords title={t('contacts.nothing')} />
      )}
    </>
  );
};
