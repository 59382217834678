import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Card, Button } from '@mui/material';
import { FiberManualRecord, Edit } from '@mui/icons-material';
import { findAll, setPage } from '../../actions/directoryActions';
import { findAll as findAllContact } from '../../actions/contactActions';
import { Context } from '../../utils/UserContext';
import { Paginator } from '../Paginator';
import { NoRecords } from '../NoRecords';
import { ButtonGroup } from '../ButtonGroup';

export const DirectoryList = React.memo(() => {
  const [t] = useTranslation('global');
  const dispatch = useDispatch();
  const history = useHistory();
  const { directories, paginator, reload } = useSelector(
    (state) => state.directory,
  );
  const { setSidenav, setLoading, setSnackbar } = useContext(Context);

  useEffect(async () => {
    try {
      setLoading(true);
      await dispatch(findAll(paginator.page));
    } catch (error) {
      setSnackbar({
        show: true,
        type: 'error',
        text: error,
      });
    } finally {
      setLoading(false);
    }
  }, [dispatch, paginator.page, reload, setLoading, setSnackbar]);

  const editDirectory = (data) => {
    setSidenav({
      show: true,
      type: false,
      data,
    });
  };

  const openDirectory = async (id) => {
    try {
      setLoading(true);
      await dispatch(findAllContact(id));
      history.push(`/directories/${id}`);
    } catch (error) {
      setSnackbar({
        show: true,
        type: 'error',
        text: error,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {directories.length ? (
        <>
          <Grid container spacing={3} className="py-1">
            {directories.map(
              ({
                id,
                is_active,
                name,
                company,
                author,
                created_at,
                updated_at,
                locations,
              }) => (
                <Grid key={id} item xs={12} sm={6} md={4}>
                  <Card
                    className="p-1 font-14 scale text-secondary pointer space-y-1 r-3"
                    onClick={() => {
                      openDirectory(id);
                    }}
                  >
                    <div className="row items-center justify-between">
                      <div className="row items-center space-x-1">
                        <FiberManualRecord
                          fontSize="small"
                          style={{
                            color: is_active
                              ? 'var(--success)'
                              : 'var(--accent)',
                          }}
                        />
                        <div>{name}</div>
                      </div>
                      <div>
                        <Button
                          size="small"
                          color="primary"
                          startIcon={<Edit />}
                          onClick={(e) => {
                            e.stopPropagation();
                            editDirectory({ id, name });
                          }}
                        >
                          {t('edit')}
                        </Button>
                      </div>
                    </div>
                    <div className="row justify-between">
                      <div>{company.name}</div>
                      <div>#{id.substr(0, 5)}</div>
                    </div>
                    <div className="row justify-between">
                      <div>
                        {t('directories.author')}:{' '}
                        {String(author?.name).substr(0, 10)}
                      </div>
                      <div>
                        {t('directories.create')}: {created_at?.substr(0, 10)}
                      </div>
                    </div>
                    <div className="font-12 mt-2 mb-1">
                      {t('directories.update')}: {updated_at?.substr(0, 10)}
                    </div>
                    {locations.length > 0 && <ButtonGroup locations={locations} />}
                  </Card>
                </Grid>
              ),
            )}
          </Grid>
          <Paginator
            data={paginator}
            setPage={(value) => dispatch(setPage(value))}
          />
        </>
      ) : (
        <NoRecords title={t('directories.nothing-title')}>
          {t('directories.nothing')}
        </NoRecords>
      )}
    </>
  );
});