import React, { useEffect, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Button, SwipeableDrawer } from '@mui/material'
import { NavigateBefore, Edit, Delete } from '@mui/icons-material'
import { Context } from '../../../utils/UserContext'
import { create, upload } from '../../../actions/contactActions'
import { ContactDetail } from './ContactDetail'
import { ContactDelete } from './ContactDelete'
import { Input, InputRadio } from '../../FormComponents'

const validationSchema = yup.object().shape({
  name: yup.string().max(64).required(),
  last_name: yup.string().max(64).required(),
  gender: yup.string().required(),
  age: yup.number().min(18).max(100).required(),
  email: yup.string().email().required(),
  phone1: yup
    .string()
    .matches(/^[0-9]+$/)
    .min(10)
    .max(16)
    .required(),
  phone2: yup
    .string()
    .matches(/^[0-9]+$/)
    .min(10)
    .max(16),
  country: yup.string().max(40).required(),
  state: yup.string().max(40).required(),
  city: yup.string().max(40).required(),
  town: yup.string().max(40).required(),
  zip_code: yup
    .string()
    .matches(/^[0-9]+$/)
    .max(5)
    .required(),
  street: yup.string().max(40).required(),
  cross_streets: yup.string().max(40),
  number: yup
    .string()
    .matches(/^[0-9]+$/)
    .min(1)
    .max(5)
    .required(),
  references: yup.string().max(255),
  delivery_info: yup.string().max(255),
})

const initialValues = {
  name: '',
  last_name: '',
  gender: '',
  age: '',
  email: '',
  phone1: '',
  phone2: '',
  country: '',
  state: '',
  city: '',
  town: '',
  zip_code: '',
  street: '',
  cross_streets: '',
  number: '',
  references: '',
  delivery_info: '',
}

export const ContactSideNav = () => {
  const [t] = useTranslation('global')
  const { sidenav, setSidenav, setSnackbar, setLoading } = useContext(Context)
  const dispatch = useDispatch()

  const { resetForm, setValues, handleChange, handleSubmit, errors, values, touched } = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true)
        const { name, last_name, gender, age, email, phone1, phone2, directory, ...address } = values
        const data = {
          name,
          last_name,
          gender,
          age,
          email,
          directory,
          address,
          phones: [phone1],
        }
        if (phone2) {
          data.phones.push(phone2)
        }
        if (sidenav.type === 'add') {
          await dispatch(create(data))
        } else {
          await dispatch(upload(data, sidenav.index))
        }
        setSnackbar({
          show: true,
          type: 'success',
          text: sidenav.type === 'add' ? t('contacts.message-create') : t('contacts.message-update'),
        })
      } catch (error) {
        setSnackbar({
          show: true,
          type: 'error',
          text: error,
        })
      } finally {
        setLoading(false)
        reset()
      }
    },
  })

  const reset = () => {
    setSidenav((values) => ({ ...values, show: false }))
    resetForm()
  }

  const cancel = () => {
    setSidenav((values) => ({ ...values, goBack: false, type: 'detail' }))
  }

  useEffect(() => {
    if (sidenav.show && sidenav.type === 'edit') {
      const { address, phones, ...payload } = sidenav.data
      const [phone1, phone2 = ''] = phones
      const data = { ...initialValues, ...payload, ...address, phone1, phone2 }
      setValues(data)
    }
  }, [sidenav])

  return (
    <SwipeableDrawer
      anchor='right'
      open={sidenav.show}
      onClose={() => setSidenav((values) => ({ ...values, show: true }))}
      onOpen={() => setSidenav((values) => ({ ...values, show: true }))}>
      <div className='sidenav-width p-3'>
        <div className='row justify-between'>
          {sidenav.goBack ? (
            <Button startIcon={<NavigateBefore />} onClick={cancel} color='primary'>
              {t('cancel')}
            </Button>
          ) : (
            <Button startIcon={<NavigateBefore />} onClick={reset} style={{ color: 'var(--info)' }}>
              {t('go-back')}
            </Button>
          )}

          {sidenav.type === 'detail' && (
            <div className='space-x-1'>
              <Button
                startIcon={<Edit />}
                onClick={() =>
                  setSidenav((values) => ({
                    ...values,
                    type: 'edit',
                    goBack: true,
                  }))
                }
                color='primary'>
                {t('edit')}
              </Button>
              <Button
                startIcon={<Delete />}
                onClick={() =>
                  setSidenav((values) => ({
                    ...values,
                    type: 'delete',
                    goBack: true,
                  }))
                }
                color='primary'>
                {t('delete')}
              </Button>
            </div>
          )}
        </div>
        {(() => {
          switch (sidenav.type) {
            case 'detail':
              return <ContactDetail data={sidenav.data} />
            case 'delete':
              return <ContactDelete />
            default:
              return (
                <div>
                  <h3 className='mt-1'>
                    {sidenav.type === 'add' ? t('new') : t('edit')} {t('contacts.contact')}
                  </h3>
                  <form noValidate autoComplete='off' className='column mt-2' onSubmit={handleSubmit}>
                    <div className='column space-y-3'>
                      <Input
                        label={t('form.names')}
                        name='name'
                        touched={touched.name}
                        value={values.name}
                        error={errors.name}
                        handleChange={handleChange}
                      />
                      <Input
                        label={t('form.lastname')}
                        name='last_name'
                        touched={touched.last_name}
                        value={values.last_name}
                        error={errors.last_name}
                        handleChange={handleChange}
                      />

                      <InputRadio
                        label={t('form.gender')}
                        name='gender'
                        touched={touched.gender}
                        value={values.gender}
                        error={errors.gender}
                        handleChange={handleChange}
                        options={[
                          {
                            value: 'female',
                            label: 'Femenino',
                          },
                          {
                            value: 'male',
                            label: 'Masculino',
                          },
                          // {
                          //   value: 'other',
                          //   label: 'No especificar',
                          // },
                        ]}
                      />

                      <Input
                        label={t('form.age')}
                        name='age'
                        touched={touched.age}
                        value={values.age}
                        error={errors.age}
                        handleChange={handleChange}
                      />

                      <Input
                        label={t('form.email')}
                        name='email'
                        touched={touched.email}
                        value={values.email}
                        error={errors.email}
                        handleChange={handleChange}
                      />

                      <Input
                        label={t('form.phone') + ' 1'}
                        name='phone1'
                        touched={touched.phone1}
                        value={values.phone1}
                        error={errors.phone1}
                        handleChange={handleChange}
                      />

                      <Input
                        label={t('form.phone') + ' 2'}
                        name='phone2'
                        touched={touched.phone2}
                        value={values.phone2}
                        error={errors.phone2}
                        handleChange={handleChange}
                      />

                      <Input
                        label={t('form.country')}
                        name='country'
                        touched={touched.country}
                        value={values.country}
                        error={errors.country}
                        handleChange={handleChange}
                      />

                      <Input
                        label={t('form.state')}
                        name='state'
                        touched={touched.state}
                        value={values.state}
                        error={errors.state}
                        handleChange={handleChange}
                      />

                      <Input
                        label={t('form.city')}
                        name='city'
                        touched={touched.city}
                        value={values.city}
                        error={errors.city}
                        handleChange={handleChange}
                      />

                      <Input
                        label={t('form.town')}
                        name='town'
                        touched={touched.town}
                        value={values.town}
                        error={errors.town}
                        handleChange={handleChange}
                      />

                      <Input
                        label={t('form.zip-code')}
                        name='zip_code'
                        touched={touched.zip_code}
                        value={values.zip_code}
                        error={errors.zip_code}
                        handleChange={handleChange}
                      />

                      <Input
                        label={t('form.street')}
                        name='street'
                        touched={touched.street}
                        value={values.street}
                        error={errors.street}
                        handleChange={handleChange}
                      />

                      <Input
                        label={t('form.number')}
                        name='number'
                        touched={touched.number}
                        value={values.number}
                        error={errors.number}
                        handleChange={handleChange}
                      />

                      <Input
                        label={t('form.cross-streets')}
                        name='cross_streets'
                        touched={touched.cross_streets}
                        value={values.cross_streets}
                        error={errors.cross_streets}
                        handleChange={handleChange}
                      />

                      <Input
                        label={t('form.references')}
                        name='references'
                        touched={touched.references}
                        value={values.references}
                        error={errors.references}
                        handleChange={handleChange}
                      />

                      <Input
                        label={t('form.delivery-info')}
                        name='delivery_info'
                        touched={touched.delivery_info}
                        value={values.delivery_info}
                        error={errors.delivery_info}
                        handleChange={handleChange}
                      />
                    </div>

                    <div className='mt-4'>
                      <Button variant='contained' color='primary' fullWidth type='submit'>
                        {sidenav.type === 'add' ? t('contacts.btn-add') : t('form.save')}
                      </Button>
                    </div>
                  </form>
                </div>
              )
          }
        })()}
      </div>
    </SwipeableDrawer>
  )
}
