import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Alert as MuiAlert, Snackbar } from '@mui/material';
import { startLogout } from '../actions/authActions';
import { Context } from '../utils/UserContext';

export const Alert = () => {
  const dispatch = useDispatch();
  let { snackbar, setSnackbar } = useContext(Context);

  if (snackbar.text === 'Request failed with status code 401') {
    snackbar.text = 'Su sesión expiró';
    setTimeout(() => {
      dispatch(startLogout());
    }, 0);
  }

  return (
    <Snackbar
      open={snackbar.show}
      autoHideDuration={2000}
      onClose={() => setSnackbar((values) => ({ ...values, show: false }))}
    >
      <MuiAlert variant="filled" severity={snackbar.type}>
        {snackbar.text}
      </MuiAlert>
    </Snackbar>
  );
};
