import React, { useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { Warning, Check } from '@mui/icons-material';
import { remove } from '../../../actions/contactActions';
import { Context } from '../../../utils/UserContext';

export const ContactDelete = () => {
  const [t] = useTranslation('global');
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const { sidenav, setSidenav } = useContext(Context);

  const deleteContact = () => {
    dispatch(remove(sidenav.index));
    setStep(2);
    setSidenav((values) => ({ ...values, goBack: false }));
  };

  return (
    <>
      {step === 1 ? (
        <>
          <h2 className="mt-2">{t('contacts.title-delete')}</h2>
          <div className="mt-3">{t('contacts.text-delete')}</div>
          <div className="row flex-center my-5">
            <div className="rounded  border-warning p-3">
              <Warning
                className="text-warning"
                style={{ width: '80px', height: '80px' }}
              />
            </div>
          </div>
          <div>
            <Button
              className="btn-xlarge"
              variant="contained"
              color="primary"
              fullWidth
              onClick={deleteContact}
            >
              {t('contacts.btn-delete')}
            </Button>
          </div>
        </>
      ) : (
        <>
          <h1 className="mt-3 text-center px-1">
            {t('contacts.contact-delete')}
          </h1>
          <div className="row flex-center mt-6">
            <div className="rounded  border-success p-2">
              <Check
                className="text-success"
                style={{ width: '100px', height: '100px' }}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};
