import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import persistState from 'redux-localstorage';
import filter from 'redux-localstorage-filter';
import adapter from 'redux-localstorage/lib/adapters/localStorage';
import { rootReducer } from '../reducers/index';

const composeEnhancers =
  (typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const storage = compose(filter(['auth']))(adapter(window.localStorage));

const middlewares = applyMiddleware(thunk);

export const store = createStore(
  rootReducer,
  composeEnhancers(middlewares, persistState(storage, 'state')),
);
