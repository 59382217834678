import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as propTypes from 'prop-types';
import { Button, TextField, CircularProgress } from '@mui/material';
import { NavigateNext, NavigateBefore, CheckCircleOutline, HighlightOff} from '@mui/icons-material';
import { postTicket } from '../../../actions/supportActions';

const ReportProblem = ({ close }) => {
  const dispatch = useDispatch();
  const { payload } = useSelector((state) => state.auth);
  const [step, setStep] = useState(1);
  const [problem, setProblem] = useState(null);
  const [t] = useTranslation('global');

  const options = [
    t('report-problem.option1'),
    t('report-problem.option2'),
    t('report-problem.option3'),
    t('report-problem.option4'),
    t('report-problem.option5'),
    t('report-problem.option6'),
    t('report-problem.option7'),
    t('report-problem.option8'),
    t('report-problem.option9'),
    t('report-problem.option10'),
  ];

  const { handleChange, handleSubmit, values, resetForm } = useFormik({
    initialValues: {
      description: '',
    },
    onSubmit: async ({ description }) => {
      try {
        setStep(3);
        const body = {
          subject: `Mailbox - ${problem}`,
          content: `${payload.email}${description && ' - ' + description}`,
        };
        await dispatch(postTicket(body));
        setStep(4);
      } catch (error) {
        setStep(5);
      } finally {
        resetForm();
      }
    },
  });

  const reset = () => {
    setStep(1);
    resetForm();
  };

  const selectProblem = (value) => {
    setProblem(value);
    setStep(2);
  };

  return (
    <>
      {(() => {
        switch (step) {
          case 1:
            return (
              <>
                <div>
                  <Button
                    onClick={close}
                    startIcon={<NavigateBefore />}
                    className="text-info"
                  >
                    {t('go-back')}
                  </Button>
                </div>
                <h2 className="mt-1">{t('report-problem.title')}</h2>
                <div className="mt-2">{t('report-problem.text')}</div>
                {options.map((text) => (
                  <Button
                    key={text}
                    variant="outlined"
                    className="home__btn-faq mt-3 border-tertiary text-tertiary"
                    fullWidth
                    endIcon={<NavigateNext />}
                    onClick={() => selectProblem(text)}
                  >
                    {text}
                  </Button>
                ))}
              </>
            );
          case 2:
            return (
              <>
                <div>
                  <Button
                    onClick={reset}
                    startIcon={<NavigateBefore />}
                    className="text-info"
                  >
                    {t('go-back')}
                  </Button>
                </div>
                <h2 className="mt-1">{problem}</h2>
                <div className="mt-2">{t('report-problem.detail')}</div>
                <form
                  noValidate
                  autoComplete="off"
                  className="column space-y-3"
                  onSubmit={handleSubmit}
                >
                  <TextField
                    fullWidth
                    className="mt-3"
                    color="secondary"
                    multiline
                    rows={4}
                    name="description"
                    variant="outlined"
                    value={values.description}
                    onChange={handleChange}
                  />

                  <div className="mt-4">
                    <Button
                      className="btn-xlarge"
                      variant="contained"
                      color="primary"
                      fullWidth
                      type="submit"
                    >
                      {t('report-problem.btn-send-report')}
                    </Button>
                  </div>
                </form>
              </>
            );
          case 3:
            return (
              <>
                <h2 className="mt-7 text-center">
                  {t('report-problem.send-report')}
                </h2>
                <div className="row flex-center p-8">
                  <CircularProgress size={155} className="text-success" />
                </div>
                <div className="text-center text-info">
                  {t('report-problem.wait')}
                </div>
              </>
            );
          case 4:
            return (
              <>
                <h2 className="mt-3 text-center">
                  {t('report-problem.thank')}
                </h2>
                <div className="text-center mt-3">
                  {t('report-problem.immediately')}
                </div>
                <div className="row flex-center p-2">
                  <CheckCircleOutline
                    className="text-success"
                    style={{ width: '200px', height: '200px' }}
                  />
                </div>
                <div>
                  <Button
                    className="mt-3 btn-xlarge"
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => setStep(1)}
                  >
                    {t('report-problem.report')}
                  </Button>
                </div>
                <div>
                  <Button
                    className="mt-3 btn-xlarge"
                    variant="outlined"
                    color="primary"
                    fullWidth
                    size="large"
                    onClick={close}
                  >
                    {t('go-back')}
                  </Button>
                </div>
              </>
            );
          case 5:
            return (
              <>
                <h2 className="mt-3 text-center">{t('report-problem.error')}</h2>
                <div className="text-center mt-3">{t('report-problem.error-sub')}</div>
                <div className="row flex-center p-2">
                  <HighlightOff
                    className="text-error"
                    style={{ width: '200px', height: '200px' }}
                  />
                </div>
                <div>
                  <Button
                    className="mt-3 btn-xlarge"
                    variant="outlined"
                    color="primary"
                    fullWidth
                    size="large"
                    onClick={close}
                  >
                    {t('go-back')}
                  </Button>
                </div>
              </>
            );
        }
      })()}
    </>
  );
};

ReportProblem.propTypes = {
  close: propTypes.func,
};

export { ReportProblem };
